.logo_link {
    background: #fff url(/images/logo.gif) no-repeat left bottom;
    height: 100px;
    width: 280px;
    display: block;
}
.logo_link:hover {
    background-position: left top;
}
.logo_link1 {
    background: url(/images/logo1.png) no-repeat left bottom;
    height: 100px;
    width: 280px;
    display: block;
}
.logo_link2 {
    background: url(/images/logo2.png) no-repeat left bottom;
    height: 100px;
    width: 280px;
    display: block;
}