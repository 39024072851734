.logo_link1 {
    background: url(/images/logo1.png) no-repeat left bottom;
    height: 100px;
    width: 280px;
    display: block;
}
.registerPageMain {
    display: grid;
    border-radius: 4px 16px 16px 4px;
    grid-template-columns: 6fr 100px 4fr;
    grid-template-rows: auto auto 1fr;
    background-color: transparent;
    box-shadow: #545b6252 0 0 6px 0px
}

.registerPartLeftBack {
    border-radius: 16px 0 0 16px;
    background-color: #fafafa;
    grid-column: 1;
    grid-row: 1/4;
}

.registerPartLeftLogo {
    grid-column: 1;
    grid-row: 1;
    justify-self: center;
    margin: 0 40px 30px 40px;
}

.registerPartLeftLogin {
    grid-column: 1;
    grid-row: 2;
    height: auto;
    display: flex;
    margin: 30px 40px;
}

.registerPartRightText b {
    margin-bottom: 10px;
    font-size: 1.2em;
    display: block
}

.registerPartCenterImage {
    background-color: #fafafa;
    grid-column: 2;
    grid-row: 1/4
}

.registerPartRightBack {
    border-radius: 0 16px 16px 0;
    background-color: #24A9D7;
    display: grid;
    grid-column: 3;
    grid-row: 1/4;
}

.registerPartRightText {
    align-self: center;
    grid-column: 3;
    grid-row: 1/4;
    margin: 30px 40px;
    color: white;
    margin-top: 0;
}

.registerPartRightBottom {
    justify-items: center;
    display: grid;
    margin-bottom: 8px;
    font-size: 0.8em;
    grid-column: 3;
    grid-row: 3;
    align-self: end;
}

@media (max-width: 992px) {
    .registerPageMain {
        border-radius: 16px 16px 4px 4px;
        grid-template-columns: 1fr;
    }

    .registerPartLeftBack {
        border-radius: 16px 16px 0 0;
        grid-column: 1;
        grid-row: 1;
        background-color: #24A9D7;
    }

    .registerPartLeftLogo {
        grid-column: 1;
        grid-row: 1;
    }

    .registerPartLeftLogo a {
        background: url(/images/logo1.png) no-repeat left bottom;
    }

    .registerPartLeftLogin {
        grid-column: 1;
        grid-row: 2;
    }

    .registerPartCenterImage {
        display: none;
    }

    .registerPartRightBack {
        grid-column: 3;
        grid-row: 1/4;
    }

    .registerPartRightText {
        grid-column: 1;
        grid-row: 3;
        color: black;
        font-size: 0.9em;
        margin-bottom: 60px;
    }
    .registerPartRightText b {
        display: none;
    }
    .registerPartRightBottom {
        grid-column: 1;
        grid-row: 3;
    }
}

@media (max-width: 476px) {
    .registerPartLeftLogo {
        margin: 0 20px 20px 20px;
    }
}

@media (max-width: 376px) {
    .registerPartLeftLogo {
        margin: 0 10px 20px 10px;
    }
}