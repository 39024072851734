header .full {
    display: grid;
    grid-area: header;
    border-top: 4px solid #444444;
    grid-template-columns: 300px 1fr;
    height: 106px;
}
header .short {
    display: grid;
    grid-area: header;
    border-top: 4px solid #444444;
    grid-template-columns: auto 1fr;
}
