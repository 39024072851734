/* Provide sufficient contrast against white background */
html, body, #root {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    background: #fff url(/images/body_bg.jpg) repeat-x top;
}

.menu a,
.menu a:visited {
    -moz-border-radius: 11px;
    -webkit-border-radius: 11px;
    border: 1px solid #fff;
    color: #666666;
    display: block;
    font-weight: bold;
    font-size: 0.9em;
    line-height: 1.0;
    padding: 4px 10px;
    text-decoration: none;
    text-transform: uppercase;
}

    .menu a:hover {
        border: 1px solid #ddd;
        color: #89c4ec;
        text-decoration: none;
    }

    .menu a.selected {
        border: 1px solid #79b6df;
        background-color: #79b6df;
        color: #fff;
    }

.menu .nav-link.selected {
    border: 1px solid #79b6df;
    background-color: #79b6df;
    color: #fff;
}

.gridcard {
    display: grid;
    grid-template-rows: auto 1fr auto;
    border: 1px solid #82BDE6;
    border-radius: .25rem;
    padding: 1.25rem;
    /*max-width: 400px;*/
    min-height: 220px;
    height: auto;
    flex-basis: 33.3%;
    flex-grow: 0;
}

.mpassword {
    background-image: url(/images/mpassword.png);
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
}

.mexpense {
    background-image: url(/images/mexpense.png);
    background-position: center;
    background-size: 70%;
    background-repeat: no-repeat;
}

.mfstorage {
    background-image: url(/images/mfstorage.png);
    background-position: center;
    background-size: 90%;
    background-repeat: no-repeat;
}
.mgames {
    background-image: url(/images/mgames.png);
    background-position: center;
    background-size: 90%;
    background-repeat: no-repeat;
}

.disabled {
    opacity: 0.3;
}

.card-title {
    margin-bottom: .75rem;
}
.MExpenseGroupView.selected {
    background-color: #eee;
}
.MExpenseCostView.selected {
    background-color: #eee;
}

.MExpenseCostEditViewItem {
    border: 1px solid transparent;
    /*padding: 6px 4px;*/
    padding-bottom: 0;
}

.MExpenseCostEditViewItem.edit {
    border: 1px solid #79b6df;
}

.dropdown-item:hover {
    background-color: rgb(102, 102, 102);
    color: white;
}
.modulename {
    display: flex;
    grid-gap: 8px;
    align-items: center;
    color: rgb(102, 102, 102);
}

.carousel-control-prev-icon {
    color: red;
}

.moduleList {
    display: grid;
    grid-gap: 15px;
    height: auto;
    grid-template-columns: 1fr 1fr 1fr;
}
@media (max-width: 992px) {
    .moduleList {
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width: 767px) {
    .moduleList {
        grid-template-columns: 1fr;
    }
}

.expenseMain {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 0.5fr 0.5fr 1fr;
}
.expenseGroups { }
.expenseCosts {}
.expenseCostDetail {}
.home {
    height: 500px;
    display: grid;
    grid-template-columns: 100px 1fr 100px;
}
@media (max-width: 767px) {
    .expenseMain {
        grid-template-columns: 0.4fr 0.6fr;
        grid-template-rows: auto 1fr;
    }
    .expenseGroups {
        font-size: 0.8em;
    }
    .expenseCosts {
        grid-column: 2;
        grid-row: 1;
        font-size: 0.8em;
    }
    .expenseCostDetail {
        grid-column: 1;
        grid-column: span 2;
        font-size: 0.8em;
    }
    .modulename {
        font-size: 1.5em;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .modulename img {
        height: 24px;
    }
    .MExpenseCostEditViewItem small {
        font-size: 0.7em;
    }
}
.logoAsText { 
    font-size: 1.4em;
    display: flex;
    align-self: center 
}
.logoAsText:hover {
    text-decoration: none;
}
    
.logoAsText .groot {
     color: #82bde6;
     font-weight: bold;
     z-index: 2
}
    .logoAsText:hover .groot {
        color: #aaa;
    }
.logoAsText .plek {
     color: black;
     font-weight: bold;
     margin-left: -9px;
     z-index: 0
}
.logoAsText .com {
    color: dimgray;
    background: #eee;
    border-radius: 8px;
    padding: 0 4px;
    font-size: 0.6em;
    font-style: italic
}
#expensesnav {
    display: grid;
    grid-template-columns: auto 1fr;
}
#monthname {
    display: flex;
    margin: auto;
}
#login .hellotext {
    align-self: flex-end;
    margin-right: 10px;
}
@media (max-width: 576px) {
    .home {
        grid-template-columns: 50px 1fr 50px;
    }
    #login {
        align-self: start;
        align-items: flex-end;
    }
/*    #login .hellotext {
        font-size: 0.9em; 
    }*/
    #expensesnav {
        grid-template-rows: auto auto;
        grid-template-columns: 1fr;
    }
    #monthname {
        display: flex;
        margin-left: 0;
    }
}
@media (max-width: 476px) {
    .home {
        grid-template-columns: 25px 1fr 25px;
    }
}
@media (max-width: 376px) {
    .home {
        grid-template-columns: auto 1fr auto;
    }
    
}