.logo_link1 {
    background: url(/images/logo1.png) no-repeat left bottom;
    height: 100px;
    width: 280px;
    display: block;
}
.loginPageMain {
    display: grid;
    border-radius: 16px 4px 4px 16px;
    grid-template-columns: 4fr 100px 6fr;
    grid-template-rows: auto auto 1fr;
    background-color: transparent;
    box-shadow: #545b6252 0 0 6px 0px
}

.partLeftBack {
    border-radius: 16px 0 0 16px;
    background-color: #24A9D7;
    grid-column: 1;
    grid-row: 1/4;
}

.partLeftLogo {
    grid-column: 1;
    grid-row: 1;
    justify-self: center;
    margin: 0 40px 30px 40px;
}

.partLeftText {
    grid-column: 1;
    grid-row: 2;
    align-self: center;
    margin: 30px 40px;
    margin-top: 0;
    color: white;
}

    .partLeftText b {
        margin-bottom: 10px;
        font-size: 1.2em;
        display: block
    }

.partCenterImage {
    background-color: #fafafa;
    grid-column: 2;
    grid-row: 1/4
}

.partRightBack {
    border-radius: 0 4px 4px 0;
    background-color: #fafafa;
    display: grid;
    grid-column: 3;
    grid-row: 1/4;
}

.partRightLogin {
    height: auto;
    display: flex;
    grid-column: 3;
    grid-row: 1/4;
    margin: 30px 40px;
}

.partRightBottom {
    justify-items: center;
    display: grid;
    margin-bottom: 8px;
    font-size: 0.8em;
    grid-column: 3;
    grid-row: 3;
    align-self: end;
}

@media (max-width: 992px) {
    .loginPageMain {
        border-radius: 16px 16px 4px 4px;
        grid-template-columns: 1fr;
    }

    .partLeftBack {
        border-radius: 16px 16px 0 0;
        grid-column: 1;
        grid-row: 1;
    }

    .partLeftLogo {
        grid-column: 1;
        grid-row: 1;
    }

    .partLeftText {
        grid-column: 1;
        grid-row: 3;
        color: black;
        margin-bottom: 60px;
        font-size: 0.9em;
    }

        .partLeftText b {
            display: none;
        }

    .partCenterImage {
        display: none;
    }

    .partRightBack {
        grid-column: 3;
        grid-row: 1/4;
    }

    .partRightLogin {
        grid-column: 1;
        grid-row: 2;
    }

    .partRightBottom {
        grid-column: 1;
        grid-row: 3;
    }
}

@media (max-width: 476px) {
    .partLeftLogo {
        margin: 0 20px 20px 20px;
    }
}

@media (max-width: 376px) {
    .partLeftLogo {
        margin: 0 10px 20px 10px;
    }
}